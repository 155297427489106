import React from 'react';
import LanguageSelect from '../language-select/LanguageSelect';
import './Header.scss';
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

const Header = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const page=location.pathname.split('/')[1].charAt(0).toUpperCase() + location.pathname.split('/')[1].slice(1)
  const string= page.toString()+'.'+page.toString()
  console.log(string)
  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      <ul className="navbar-nav">
        <li className="nav-item sidebar-menu">
          <button className="navbar-toggler d-inline-block" data-widget="pushmenu">
            <span className="navbar-toggler-icon"></span>
          </button>
        </li>
        <li className=' nav-item name-header'>
        {t(string)}
        </li>
        <li className="nav-item language-select-header">
          <LanguageSelect></LanguageSelect>
        </li>
      </ul>
    </nav>
  )
}


export default Header;
