import { GET_USERS, GET_ALL_USERS, LOCK_USER_ACTION } from '../actions/index'
import { LANGUAGE_CHANGE } from '../actions/index'
const INITIAL_STATE = {
  users: [],
  all_users:[],
  loadingLawers: true,
  loadingAllUsers:true,
  lang: 'en'
}
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
        loadingLawers: false
      }
    case GET_ALL_USERS:  
    return {
      ...state,
      all_users: action.payload,
      loadingAllUsers: false
    }
    case LANGUAGE_CHANGE:
      return {
        ...state,
        lang: action.payload,

      }
      case LOCK_USER_ACTION:
        return {
          ...state,
          all_users: [
            ...state.all_users.map((user) => user.id === action.payload ? { ...user, locking: !user.locking } : { ...user, locking: false })
          ],
        }
    default: return state;
  };
}
