import { createAction } from 'redux-actions';
export const LOGIN_ACTION = "LOGIN_ACTION"
export const LOGIN_FAILURE = "LOGIN_FAILURE"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGOUT_ACTION = "LOGOUT_ACTION"
export const REGISTER_ACTION = "REGISTER_ACTION"
export const GET_USER_INFO = "GET_USER_INFO"
export const GET_USER_PROFILE = "GET_USER_PROFILE"
export const GET_ROLE = "GET_ROLE"
export const GET_USERS = "GET_USERS"
export const GET_ALL_USERS = "GET_ALL_USERS"
export const LOCK_USER_ACTION = "LOCK_USER_ACTION"
export const LANGUAGE_CHANGE = "LANGUAGE_CHANGE"

export const GET_CONTACTS = "GET_CONTACTS"
export const GET_CONTACT_SELECTIONS="GET_CONTACT_SELECTIONS"
export const ADD_CONTACT = "ADD_CONTACT"
export const DELETE_CONTACT = "DELETE_CONTACT"
export const EDIT_CONTACT = "EDIT_CONTACT"
export const DELETE_CONTACT_ACTION="DELETE_CONTACT_ACTION"
export const UPDATE_CONTACT = "UPDATE_CONTACT"
export const OPEN_DELETE_CONTACT = "OPEN_DELETE_CONTACT"

export const GET_CASES = "GET_CASES"
export const ADD_CASE = "ADD_CASE"
export const DELETE_CASE = "DELETE_CASE"
export const EDIT_CASE = "EDIT_CASE"
export const DELETE_CASE_ACTION="DELETE_CASE_ACTION"
export const UPDATE_CASE = "UPDATE_CASE"
export const CASE_DETAILS = "CASE_DETAILS"
export const GET_USERS_FOR_CASE = "GET_USERS_FOR_CASE"

export const GET_HEARINGS = "GET_HEARINGS"
export const GET_CASES_HEARINGS= "GET_CASES_HEARINGS"
export const ADD_HEARING = "ADD_HEARING"
export const DELETE_HEARING = "DELETE_HEARING"
export const EDIT_HEARING = "EDIT_HEARING"
export const DELETE_HEARING_ACTION="DELETE_HEARING_ACTION"
export const UPDATE_HEARING = "UPDATE_HEARING"
export const FILTER_HEARINGS = "FILTER_HEARINGS"

export const GET_CASE_TYPES = "GET_CASE_TYPES"
export const ADD_CASE_TYPE = "ADD_CASE_TYPE"
export const DELETE_CASE_TYPE = "DELETE_CASE_TYPE"
export const EDIT_CASE_TYPE = "EDIT_CASE_TYPE"
export const DELETE_CASE_TYPE_ACTION="DELETE_CASE_TYPE_ACTION"
export const UPDATE_CASE_TYPE = "UPDATE_CASE_TYPE"

export const GET_REGISTER_TYPES = "GET_REGISTER_TYPES"
export const ADD_REGISTER_TYPE = "ADD_REGISTER_TYPE"
export const DELETE_REGISTER_TYPE = "DELETE_REGISTER_TYPE"
export const EDIT_REGISTER_TYPE = "EDIT_REGISTER_TYPE"
export const DELETE_REGISTER_TYPE_ACTION="DELETE_REGISTER_TYPE_ACTION"
export const UPDATE_REGISTER_TYPE = "UPDATE_REGISTER_TYPE"

export const GET_COURT_TYPES = "GET_COURT_TYPES"
export const ADD_COURT_TYPE = "ADD_COURT_TYPE"
export const DELETE_COURT_TYPE = "DELETE_COURT_TYPE"
export const EDIT_COURT_TYPE = "EDIT_COURT_TYPE"
export const DELETE_COURT_TYPE_ACTION="DELETE_COURT_TYPE_ACTION"
export const UPDATE_COURT_TYPE = "UPDATE_COURT_TYPE"

export const GET_ACTION_TYPES = "GET_ACTION_TYPES"
export const ADD_ACTION_TYPE = "ADD_ACTION_TYPE"
export const DELETE_ACTION_TYPE = "DELETE_ACTION_TYPE"
export const DELETE_ACTION_TYPE_ACTION="DELETE_ACTION_TYPE_ACTION"
export const EDIT_ACTION_TYPE = "EDIT_ACTION_TYPE"
export const UPDATE_ACTION_TYPE = "UPDATE_ACTION_TYPE"

export const GET_CASE_CATEGORIES = "GET_CASE_CATEGORIES"
export const ADD_CASE_CATEGORY = "ADD_CASE_CATEGORY"
export const DELETE_CASE_CATEGORY = "DELETE_CASE_CATEGORY"
export const EDIT_CASE_CATEGORY = "EDIT_CASE_CATEGORY"
export const DELETE_CASE_CATEGORY_ACTION="DELETE_CASE_CATEGORY_ACTION"
export const UPDATE_CASE_CATEGORY = "UPDATE_CASE_CATEGORY"

export const GET_ACTIONS = "GET_ACTIONS"
export const ADD_ACTION = "ADD_ACTION"
export const DELETE_ACTION = "DELETE_ACTION"
export const EDIT_ACTION = "EDIT_ACTION"
export const DELETE_ACTION_ACTION="DELETE_ACTION_ACTION"
export const UPDATE_ACTION = "UPDATE_ACTION"

export const GET_PARTY_TYPES = "GET_PARTY_TYPES"
export const ADD_PARTY_TYPE = "ADD_PARTY_TYPE"
export const DELETE_PARTY_TYPE = "DELETE_PARY_TYPE"
export const EDIT_PARTY_TYPE = "EDIT_PARTY_TYPE"
export const DELETE_PARTY_TYPE_ACTION="DELETE_PARTY_TYPE_ACTION"
export const UPDATE_PARTY_TYPE = "UPDATE_PARTY_TYPE"

export const GET_COMPANIES = "GET_COMPANIES"
export const ADD_COMPANY = "ADD_COMPANY"
export const DELETE_COMPANY = "DELETE_COMPANY"
export const EDIT_COMPANY = "EDIT_COMPANY"
export const DELETE_COMPANY_ACTION="DELETE_COMPANY_ACTION"
export const UPDATE_COMPANY = "UPDATE_COMPANY"

//user
export const loginUser = createAction(LOGIN_ACTION);
export const logoutUser = createAction(LOGOUT_ACTION);
export const registerUser = createAction(REGISTER_ACTION)
export const logInFailure = createAction(LOGIN_FAILURE)
export const logInSuccess = createAction(LOGIN_SUCCESS)
export function getUser
    (user) {
    return {
        type: GET_USER_INFO,
        payload: user
    }
}
export function getUserProfileSuccess
    (user) {
    return {
        type: GET_USER_PROFILE,
        payload: user
    }
}
export function getRoleSuccess
    (role) {
    return {
        type: GET_ROLE,
        payload: role
    }
}
export function getLawersSuccess
    (users) {
    return {
        type: GET_USERS,
        payload: users
    }
}

export function getUsersSuccess
(users) {
    return {
        type: GET_ALL_USERS,
        payload: users
    }
}
export function lockUserAction(id){
    return{
        type:LOCK_USER_ACTION,
        payload:id
    }
}
export function languageChange
    (lang) {
    return {
        type: LANGUAGE_CHANGE,
        payload: lang
    }
}

//contacts
export const getContacts = createAction(GET_CONTACTS)
export const getContactSelections=createAction(GET_CONTACT_SELECTIONS)
export function addContactSuccess
    (contact) {
    return {
        type: ADD_CONTACT,
        payload: contact
    }
}
export function deleteContactSuccess(id) {
    return {
        type: DELETE_CONTACT,
        payload: id
    }
}
export function openDeleteModelContact(id) {
    return {
        type: OPEN_DELETE_CONTACT,
        payload: id
    }
}
export function editContact(id) {
    return {
        type: EDIT_CONTACT,
        payload: id
    }
}
export function deleteContactAction(id){
    return{
        type:DELETE_CONTACT_ACTION,
        payload:id
    }
}
export function updateContact(contact) {
    return {
        type: UPDATE_CONTACT,
        payload: contact
    }
}

//cases
export const getCases = createAction(GET_CASES)
export function addCaseSuccess
    (returnCase) {
    return {
        type: ADD_CASE,
        payload: returnCase
    }
}
export function deleteCaseSuccess(id) {
    return {
        type: DELETE_CASE,
        payload: id
    }
}
export function deleteCaseAction(id){
    return{
        type:DELETE_CASE_ACTION,
        payload:id
    }
}
export function editCase(id) {
    return {
        type: EDIT_CASE,
        payload: id
    }
}
export function updateCase(editCase) {
    return {
        type: UPDATE_CASE,
        payload: editCase
    }
}
export function caseDetails(caseDetails) {
    return {
        type: CASE_DETAILS,
        payload: caseDetails
    }
}
export function getUsersForCaseSuccess(users) {
    return {
        type: GET_USERS_FOR_CASE,
        payload: users
    }
}


//hearings

export const getHearings = createAction(GET_HEARINGS)
export const getCasesHearingsAction = createAction(GET_CASES_HEARINGS)

export function addHearingSuccess
    (returnHearing) {
    return {
        type: ADD_HEARING,
        payload: returnHearing
    }
}
export function deleteHearingSuccess(id) {
    return {
        type: DELETE_HEARING,
        payload: id
    }
}
export function deleteHearingAction(id){
    
    return{
        type:DELETE_HEARING_ACTION,
        payload:id
    }
}
export function editHearing(id) {
    return {
        type: EDIT_HEARING,
        payload: id
    }
}
export function updateHearing(editHearing) {
    return {
        type: UPDATE_HEARING,
        payload: editHearing
    }
}
export function filterHearingsAction(filterHearings) {
    return {
        type: FILTER_HEARINGS,
        payload: filterHearings
    }
}
//case types

export const getCaseTypes = createAction(GET_CASE_TYPES)
export function addCaseTypeSuccess
    (returnCaseType) {
    return {
        type: ADD_CASE_TYPE,
        payload: returnCaseType
    }
}
export function deleteCaseTypeSuccess(id) {
    return {
        type: DELETE_CASE_TYPE,
        payload: id
    }
}
export function deleteCaseTypeAction(id){
    return{
        type:DELETE_CASE_TYPE_ACTION,
        payload:id
    }
}
export function editCaseType(id) {
    return {
        type: EDIT_CASE_TYPE,
        payload: id
    }
}
export function updateCaseType(editCaseType) {
    return {
        type: UPDATE_CASE_TYPE,
        payload: editCaseType
    }
}

//register types

export const getRegisterTypes = createAction(GET_REGISTER_TYPES)
export function addRegisterTypeSuccess
    (returnRegisterType) {
    return {
        type: ADD_REGISTER_TYPE,
        payload: returnRegisterType
    }
}
export function deleteRegisterTypeSuccess(id) {
    return {
        type: DELETE_REGISTER_TYPE,
        payload: id
    }
}
export function editRegisterType(id) {
    return {
        type: EDIT_REGISTER_TYPE,
        payload: id
    }
}
export function deleteRegisterTypeAction(id){
    return{
        type:DELETE_REGISTER_TYPE_ACTION,
        payload:id
    }
}
export function updateRegisterType(editRegisterType) {
    return {
        type: UPDATE_REGISTER_TYPE,
        payload: editRegisterType
    }
}

//court types

export const getCourtTypes = createAction(GET_COURT_TYPES)
export function addCourtTypeSuccess
    (returnCourtType) {
    return {
        type: ADD_COURT_TYPE,
        payload: returnCourtType
    }
}
export function deleteCourtTypeSuccess(id) {
    return {
        type: DELETE_COURT_TYPE,
        payload: id
    }
}
export function deleteCourtTypeAction(id){
    return{
        type:DELETE_COURT_TYPE_ACTION,
        payload:id
    }
}
export function editCourtType(id) {
    return {
        type: EDIT_COURT_TYPE,
        payload: id
    }
}
export function updateCourtType(editCourtType) {
    return {
        type: UPDATE_COURT_TYPE,
        payload: editCourtType
    }
}

//action type

export const getActionTypes = createAction(GET_ACTION_TYPES)
export function addActionTypeSuccess
    (returnActionType) {
    return {
        type: ADD_ACTION_TYPE,
        payload: returnActionType
    }
}
export function deleteActionTypeSuccess(id) {
    return {
        type: DELETE_ACTION_TYPE,
        payload: id
    }
}
export function deleteActionTypeAction(id){
    return{
        type:DELETE_ACTION_TYPE_ACTION,
        payload:id
    }
}
export function editActionType(id) {
    return {
        type: EDIT_ACTION_TYPE,
        payload: id
    }
}
export function updateActionType(editActionType) {
    return {
        type: UPDATE_ACTION_TYPE,
        payload: editActionType
    }
}
//case category 
export const getCaseCategories = createAction(GET_CASE_CATEGORIES)
export function addCaseCategorySuccess
    (returnCaseCategory) {
    return {
        type: ADD_CASE_CATEGORY,
        payload: returnCaseCategory
    }
}
export function deleteCaseCategorySuccess(id) {
    return {
        type: DELETE_CASE_CATEGORY,
        payload: id
    }
}
export function deleteCaseCategoryAction(id){
    return{
        type:DELETE_CASE_CATEGORY_ACTION,
        payload:id
    }
}
export function editCaseCategory(id) {
    return {
        type: EDIT_CASE_CATEGORY,
        payload: id
    }
}
export function updateCaseCategory(editCaseCategory) {
    return {
        type: UPDATE_CASE_CATEGORY,
        payload: editCaseCategory
    }
}

//actions
export const getActions = createAction(GET_ACTIONS)
export function addActionSuccess
    (returnAction) {
    return {
        type: ADD_ACTION,
        payload: returnAction
    }
}
export function deleteActionSuccess(id) {
    return {
        type: DELETE_ACTION,
        payload: id
    }
}
export function deleteActionAction(id){
    return{
        type:DELETE_ACTION_ACTION,
        payload:id
    }
}
export function editAction(id) {
    return {
        type: EDIT_ACTION,
        payload: id
    }
}
export function updateAction(editAction) {
    return {
        type: UPDATE_ACTION,
        payload: editAction
    }
}

//party types
export const getPartyTypes = createAction(GET_PARTY_TYPES)
export function addPartyTypeSuccess
    (returnPartyType) {
    return {
        type: ADD_PARTY_TYPE,
        payload: returnPartyType
    }
}
export function deletePartyTypeSuccess(id) {
    return {
        type: DELETE_PARTY_TYPE,
        payload: id
    }
}
export function deletePartyTypeAction(id){
    return{
        type:DELETE_PARTY_TYPE_ACTION,
        payload:id
    }
}
export function editPartyType(id) {
    return {
        type: EDIT_PARTY_TYPE,
        payload: id
    }
}
export function updatePartyType(editPartyType) {
    return {
        type: UPDATE_PARTY_TYPE,
        payload: editPartyType
    }
}

//companies

export const getCompanies = createAction(GET_COMPANIES)
export function addCompanySuccess
    (returnCompany) {
    return {
        type: ADD_COMPANY,
        payload: returnCompany
    }
}
export function deleteCompanySuccess(id) {
    return {
        type: DELETE_COMPANY,
        payload: id
    }
}
export function deleteCompanyAction(id){
    return{
        type:DELETE_COMPANY_ACTION,
        payload:id
    }
}
export function editCompany(id) {
    return {
        type: EDIT_COMPANY,
        payload: id
    }
}
export function updateCompany(editCompany) {
    return {
        type: UPDATE_COMPANY,
        payload: editCompany
    }
}
