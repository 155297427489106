import { GET_CONTACTS, ADD_CONTACT, DELETE_CONTACT_ACTION, DELETE_CONTACT, EDIT_CONTACT, UPDATE_CONTACT, GET_CONTACT_SELECTIONS } from '../actions/index';

const INITIAL_STATE = {
  contacts: [],
  contact: {
    firstName: null,
    phone_1: null,
    phone_2: null,
    company: null,
    legalEntity: null
  },
  contactSelections: [],
  loadingContacts: true,
  contactsPaging: {
    CurrentPage: 1,
    HasNext: true,
    HasPrevious: false,
    PageSize: 10,
    TotalCount: 1,
    TotalPages: 1,
    PageNumber: 1
  },
}
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CONTACTS:
      console.log('Contact reducer', action.payload.data)
      return {
        ...state,
        contacts: action.payload.data,
        contactsPaging: action.payload.pagination,
        loadingContacts: false
      }
    case GET_CONTACT_SELECTIONS:
      return {
        ...state,
        contactSelections: action.payload,
      }
    case ADD_CONTACT:
      let newcontact = action.payload
      return {
        ...state,
        contacts: [...state.contacts, newcontact],
        contactSelections: [...state.contactSelections, newcontact],
        contactsPaging: {
          ...state.contactsPaging,
          TotalCount: state.contactsPaging.TotalCount + 1,
        }
      }
    case DELETE_CONTACT:
      return {
        ...state,
        contacts: [
          ...state.contacts.filter((contact) => contact.id !== action.payload)
        ],
        contactsPaging: {
          ...state.contactsPaging,
          TotalCount: state.contactsPaging.TotalCount - 1,
        }
      }
    case DELETE_CONTACT_ACTION:
      return {
        ...state,
        contacts: [
          ...state.contacts.map((contact) => contact.id === action.payload ? { ...contact, deleting: !contact.deleting } : { ...contact, deleting: false })
        ],
      }
    case EDIT_CONTACT:
      return {
        ...state,
        contacts: [
          ...state.contacts.map((contact) => contact.id === action.payload ? { ...contact, editing: !contact.editing } : { ...contact, editing: false })
        ],
      }
    case UPDATE_CONTACT:
      return {
        ...state,
        contacts: [...state.contacts.map((contact) => {
          if (contact.id === action.payload.id) {
            return {
              ...contact,
              name: action.payload.name,
              phone_1: action.payload.phone_1,
              phone_2: action.payload.phone_2,
              mobile: action.payload.mobile,
              address: action.payload.address,
              email: action.payload.email,
              occupation: action.payload.occupation,
              company: action.payload.company,
              isLegalEntity: action.payload.isLegalEntity,
              identityCardNumber: action.payload.identityCardNumber,
              ssn: action.payload.ssn,
              passportNumber: action.payload.passportNumber,
              identifier: action.payload.identifier,
              vatNumber: action.payload.vatNumber,
              registrationNumber: action.payload.registrationNumber,
              contactType: action.payload.contactType
            }
          } else return contact;
        })]
      }
    default: return state
  }
}