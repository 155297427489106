import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { languageChange } from '../../actions/index';
import './LanguageSelect.scss';

const LanguageSelect = () => {
    const { i18n } = useTranslation();
    const dispatch = useDispatch()

    function handleClick(lang) {
        i18n.changeLanguage(lang);
        localStorage.setItem('language', lang)
        dispatch(languageChange(lang))
    }

    const language = localStorage.getItem("language")
    return (
        <select className="form-control select-logInPage" value={language || "en"} onChange={e => handleClick(e.target.value)} >
            <option value='en'>EN</option>
            <option value='srb'>SRB</option>
        </select>
    )
}

export default LanguageSelect;
