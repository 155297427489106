import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist';
import immutableTransform from 'redux-persist-transform-immutable';
import storage from 'redux-persist/lib/storage';
import auth from './auth.reducer';
import contacts from './contacts.reducer';
import hearings from './hearings.reducer';
import cases from './cases.reducer';
import users from './users.reducers';
import actions from './actions.reducers';
import settings from './settings.reducer';


const persistConfig = {
  key: 'root',
  transforms: [immutableTransform()],
  storage,
  whitelist: ['auth'],
};
const rootReducer = combineReducers({
  auth,
  contacts,
  hearings,
  cases,
  users,
  actions,
  settings
});

export default persistReducer(persistConfig, rootReducer);