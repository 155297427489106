import { GET_ACTIONS, ADD_ACTION, DELETE_ACTION, EDIT_ACTION, UPDATE_ACTION, DELETE_ACTION_ACTION } from '../actions/index';

const INITIAL_STATE = {
  actions: [],
  action: {
    note: "",
    caseId: "",
    case: null,
    hearingId: "",
    hearing: null,
    actionType: null,
    dateCreated: null,
    dueDate: null

  },
  loadingActions: true,
  actionsPaging: {
    CurrentPage: 1,
    HasNext: true,
    HasPrevious: false,
    PageSize: 10,
    TotalCount: 1,
    TotalPages: 1,
    PageNumber: 1
  },

}
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_ACTIONS:
      return {
        ...state,
        actions: action.payload.data,
        actionsPaging: action.payload.pagination,
        loadingActions: false
      }
    case ADD_ACTION:
      let newAction = action.payload
      return {
        ...state,
        actions: [...state.actions, newAction],
        actionsPaging: {
          ...state.actionsPaging,
          TotalCount: state.actionsPaging.TotalCount + 1,
        }
      }
    case DELETE_ACTION:
      return {
        ...state,
        actions: [
          ...state.actions.filter((deleteAction) => deleteAction.id !== action.payload)
        ],
        actionsPaging: {
          ...state.actionsPaging,
          TotalCount: state.actionsPaging.TotalCount - 1,
        }
      }
    case DELETE_ACTION_ACTION:
      return {
        ...state,
        actions: [
          ...state.actions.map((oneAction) => oneAction.id === action.payload ? { ...oneAction, deleting: !oneAction.deleting } : { ...oneAction, deleting: false })
        ],
      }
    case EDIT_ACTION:
      return {
        ...state,
        actions: [
          ...state.actions.map((actionEdit) => actionEdit.id === action.payload ? { ...actionEdit, editing: !actionEdit.editing } : { ...actionEdit, editing: false })
        ],
      }
    case UPDATE_ACTION:
      return {
        ...state,
        actions: [...state.actions.map((actionEdit) => {
          if (actionEdit.id === action.payload.id) {
            return {
              ...actionEdit,
              note: action.payload.note,
              caseId: action.payload.caseId,
              case: action.payload.case,
              hearingId: action.payload.hearingId,
              hearing: action.payload.hearing,
              dateCreated: action.payload.dateCreated,
              dueDate: action.payload.dueDate,
              actionTypeId: action.payload.actionTypeId,
              actionType: action.payload.actionType,
              userActions: action.payload.userActions,
              clientId : action.payload.clientId,
              clientAksId : action.payload.clientAksId,
              secondPartyId : action.payload.secondPartyId,
              clientContact:action.payload.clientContact,
              clientAksContact : action.payload.clientAksContact,
              secondPartyContact : action.payload.secondPartyContact
            }

            
          } else return actionEdit;
        })]
      }
    default: return state
  };
}