import './thirdParty.js';
import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import './App.css';
import reducers from './reducers';
import Routes from './routes';
import './translation/i-18-n';

const store = createStore(reducers, composeWithDevTools(
  applyMiddleware(thunk),
));

if (process.env.NODE_ENV === 'development') {
  window.store = store;
}
if (process.env.NODE_ENV === 'production') {
  console.log = function () { };
}

if (module.hot) {
  module.hot.accept();
}

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={(<div>Loading </div>)}>
        <Routes />
      </Suspense>
    </Provider>
  );
}

const mountNode = document.getElementById('root');
render(
  <React.StrictMode>
    <AppWrapper />
  </React.StrictMode>,
  mountNode
);
