import { GET_CASES, ADD_CASE, DELETE_CASE, DELETE_CASE_ACTION, EDIT_CASE, UPDATE_CASE, CASE_DETAILS, GET_USERS_FOR_CASE } from '../actions/index';


const INITIAL_STATE = {
  cases: [
    {   title: null,
      caseIdentifier: null,
      caseTypeId: null,
      case: null,
      judge: null,
      prosecutor: null,
      defendant: null,
      caseType: null,
      registerType: null,
      court: null,
      courtId: null,
      registerTypeId: null,
      prosecutorId: null,
      defendantId: null,
      judgeId: null,
      dateCreated: null,
      caseCategoryId: null,
      caseCategory: null,
      parentCaseId: null,
      parentCase: null,
      thirdPartyId: null,
      partyTypeId: null
    },
    {
      title: null,
      caseIdentifier: null,
      caseTypeId: null,
      case: null,
      judge: null,
      prosecutor: null,
      defendant: null,
      caseType: null,
      registerType: null,
      court: null,
      courtId: null,
      registerTypeId: null,
      prosecutorId: null,
      defendantId: null,
      judgeId: null,
      dateCreated: null,
      caseCategoryId: null,
      caseCategory: null,
      parentCaseId: null,
      parentCase: null,
      thirdPartyId: null,
      partyTypeId: null

    }, {
      title: null,
      caseIdentifier: null,
      caseTypeId: null,
      case: null,
      judge: null,
      prosecutor: null,
      defendant: null,
      caseType: null,
      registerType: null,
      court: null,
      courtId: null,
      registerTypeId: null,
      prosecutorId: null,
      defendantId: null,
      judgeId: null,
      dateCreated: null,
      caseCategoryId: null,
      caseCategory: null,
      parentCaseId: null,
      parentCase: null,
      thirdPartyId: null,
      partyTypeId: null

    },
    {
      title: null,
      caseIdentifier: null,
      caseTypeId: null,
      case: null,
      judge: null,
      prosecutor: null,
      defendant: null,
      caseType: null,
      registerType: null,
      court: null,
      courtId: null,
      registerTypeId: null,
      prosecutorId: null,
      defendantId: null,
      judgeId: null,
      dateCreated: null,
      caseCategoryId: null,
      caseCategory: null,
      parentCaseId: null,
      parentCase: null,
      thirdPartyId: null,
      partyTypeId: null

    },
    {
      title: null,
      caseIdentifier: null,
      caseTypeId: null,
      case: null,
      judge: null,
      prosecutor: null,
      defendant: null,
      caseType: null,
      registerType: null,
      court: null,
      courtId: null,
      registerTypeId: null,
      prosecutorId: null,
      defendantId: null,
      judgeId: null,
      dateCreated: null,
      caseCategoryId: null,
      caseCategory: null,
      parentCaseId: null,
      parentCase: null,
      thirdPartyId: null,
      partyTypeId: null

    },
    {
      title: null,
      caseIdentifier: null,
      caseTypeId: null,
      case: null,
      judge: null,
      prosecutor: null,
      defendant: null,
      caseType: null,
      registerType: null,
      court: null,
      courtId: null,
      registerTypeId: null,
      prosecutorId: null,
      defendantId: null,
      judgeId: null,
      dateCreated: null,
      caseCategoryId: null,
      caseCategory: null,
      parentCaseId: null,
      parentCase: null,
      thirdPartyId: null,
      partyTypeId: null

    },
    {
      title: null,
      caseIdentifier: null,
      caseTypeId: null,
      case: null,
      judge: null,
      prosecutor: null,
      defendant: null,
      caseType: null,
      registerType: null,
      court: null,
      courtId: null,
      registerTypeId: null,
      prosecutorId: null,
      defendantId: null,
      judgeId: null,
      dateCreated: null,
      caseCategoryId: null,
      caseCategory: null,
      parentCaseId: null,
      parentCase: null,
      thirdPartyId: null,
      partyTypeId: null

    },
    {
      title: null,
      caseIdentifier: null,
      caseTypeId: null,
      case: null,
      judge: null,
      prosecutor: null,
      defendant: null,
      caseType: null,
      registerType: null,
      court: null,
      courtId: null,
      registerTypeId: null,
      prosecutorId: null,
      defendantId: null,
      judgeId: null,
      dateCreated: null,
      caseCategoryId: null,
      caseCategory: null,
      parentCaseId: null,
      parentCase: null,
      thirdPartyId: null,
      partyTypeId: null

    },
  ],
  caseDetails: '',
  loadingCaseDetails:false,
  lawers: [],
  loadingCases: true,
  casesPaging: {
    CurrentPage: 1,
    HasNext: true,
    HasPrevious: false,
    PageSize: 8,
    TotalCount: 1,
    TotalPages: 1,
    PageNumber: 1
  },

}
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CASES:
      return {
        ...state,
        cases: action.payload.data,
        casesPaging: action.payload.pagination,
        loadingCases: false
      }
    case ADD_CASE:
      let newCase = action.payload
      return {
        ...state,
        cases: [...state.cases, newCase],
        casesPaging: {
          ...state.casesPaging,
          TotalCount: state.casesPaging.TotalCount + 1,
        }
      }
    case DELETE_CASE:
      return {
        ...state,
        cases: [
          ...state.cases.filter((deleteCase) => deleteCase.id !== action.payload)
        ],
        casesPaging: {
          ...state.casesPaging,
          TotalCount: state.casesPaging.TotalCount - 1,
        }
      }
    case DELETE_CASE_ACTION:
      return {
        ...state,
        cases: [
          ...state.cases.map((caseOne) => caseOne.id === action.payload ? { ...caseOne, deleting: !caseOne.deleting } : { ...caseOne, deleting: false })
        ],
      }
    case EDIT_CASE:
      return {
        ...state,
        cases: [
          ...state.cases.map((caseEdit) => caseEdit.id === action.payload ? { ...caseEdit, editing: !caseEdit.editing } : { ...caseEdit, editing: false })
        ],
      }
    case UPDATE_CASE:
      return {
        ...state,
        cases: [...state.cases.map((caseEdit) => {
          if (caseEdit.id === action.payload.id) {
            return {
              ...caseEdit,
              title: action.payload.title,
              caseIdentifier: action.payload.caseIdentifier,
              caseTypeId: action.payload.caseTypeId,
              case: action.payload.case,
              judge: action.payload.judge,
              prosecutor: action.payload.prosecutor,
              defendant: action.payload.defendant,
              caseType: action.payload.caseType,
              registerType: action.payload.registerType,
              court: action.payload.court,
              courtId: action.payload.courtId,
              registerTypeId: action.payload.registerTypeId,
              prosecutorId: action.payload.prosecutorId,
              defendantId: action.payload.defendantId,
              judgeId: action.payload.defendantId,
              dateCreated: action.payload.dateCreated,
              caseCategoryId: action.payload.caseCategoryId,
              caseCategory: action.payload.caseCategory,
              parentCaseId: action.payload.parentCaseId,
              parentCase: action.payload.parentCase,
              thirdPartyId: action.payload.thirdPartyId,
              partyTypeId: action.payload.partyTypeId,
              userCases:action.payload.userCases,
              usersId:action.payload.userId

            }
          } else return caseEdit;
        })]
      }
    case CASE_DETAILS:
      return {
        ...state,
        caseDetails: action.payload,
        loadingCaseDetails:true
      }
    case GET_USERS_FOR_CASE:
      return {
        ...state,
        lawers: action.payload
      }
    default: return state
  }
}
