import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import '../../override.css';
import './Logout.scss'
import { Modal } from 'react-bootstrap'

const Logout = (props) => {

  const history = useHistory();
  const { t, i18n } = useTranslation();

  function handleLogout(e) {
    localStorage.removeItem('access-token')
    localStorage.removeItem('refresh-token')
    localStorage.removeItem('refresh')
    localStorage.removeItem('firstName')
    localStorage.removeItem('lastName')
    localStorage.removeItem('userName')
    localStorage.removeItem('active-tab-calendar')
    localStorage.removeItem('active-tab-settings')
    // localStorage.removeItem('language')
    localStorage.removeItem('role')
    i18n.changeLanguage(localStorage.getItem('language'));
    history.push('/')

  }
  return (
    <Modal centered show={props.show} onHide={props.handleClose}>

      <Modal.Header closeButton>
        <h5 className="modal-title div_font_bold"> <i className="nav-icon fas fa-sign-out-alt" />{t('Logout.Logout')} </h5>
      </Modal.Header>
      <Modal.Body>
        <p>{t('Logout.LogoutText')}</p>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-yellow" onClick={handleLogout}>{t('Buttons.Yes')}</button>
        <button type="button" className="btn btn-red" data-dismiss="modal" onClick={props.handleClose}>{t('Buttons.No')}</button>
      </Modal.Footer>
    </Modal>


  )
}
export default Logout