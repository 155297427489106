import loadable from '@loadable/component';
import React from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import Header from '../components/header/Header';
import Menu from '../components/menu/Menu';
import { roleAdmin } from '../constants/Constants';

const AdminRoutes = () => <>
  <Header />
  <Menu />
  <div className="content-wrapper page-background flex-grow-1">
    <Switch>
      <Route path="/profile" exact component={loadable(() => import('../components/contact-row/ContactRow'))} />
      <Route path='/home' exact component={loadable(() => import('../components/home/Home'))} />
      <Route path='/contacts' exact component={loadable(() => import('../pages/contacts/ContactsPage'))} />
      <Route path='/cases' exact component={loadable(() => import('../pages/cases/CasesPage'))} />
      <Route path='/hearings' exact component={loadable(() => import('../pages/hearings/HearingsPage'))} />
      <Route path='/actions' exact component={loadable(() => import('../pages/actions/ActionsPage'))} />
      <Route path='/users' exact component={loadable(() => import('../pages/users/UsersPage'))} />
      <Route path='/settings' exact component={loadable(() => import('../pages/settings/SettingsPage'))} />
      <Route path='/calendar' exact component={loadable(() => import('../pages/calendar/CalendarPage'))} />
      <Route path='/caseDetails' exact component={loadable(() => import('../pages/caseDetails/CaseDetails'))} />
      {/* <Route path='/about' />
      <Route path='/contact' /> */}
    </Switch>
  </div>
</>;

const UserRouts = () =>
  <>
    <Header />
    <Menu />
    <div className="content-wrapper page-background flex-grow-1">
      <Switch>
        <Route path="/profile" exact component={loadable(() => import('../components/contact-row/ContactRow'))} />
        <Route path='/home' exact component={loadable(() => import('../components/home/Home'))} />
        <Route path='/contacts' exact component={loadable(() => import('../pages/contacts/ContactsPage'))} />
        <Route path='/hearings' exact component={loadable(() => import('../pages/hearings/HearingsPage'))} />
        <Route path='/cases' exact component={loadable(() => import('../pages/cases/CasesPage'))} />
        <Route path='/actions' exact component={loadable(() => import('../pages/actions/ActionsPage'))} />
        <Route path='/calendar' exact component={loadable(() => import('../pages/calendar/CalendarPage'))} />
        <Route path='/caseDetails' exact component={loadable(() => import('../pages/caseDetails/CaseDetails'))} />
        {/* <Route path='/about' />
        <Route path='/contact' /> */}
        <Redirect to="/login" />
      </Switch>
    </div>
  </>

const LoggedOutList = () => (
  <Switch>
    <Route exact path="/login" component={loadable(() => import('../pages/login/LoginPage'))} />
    {/* <Route exact path="/register" component={loadable(() => import('../pages/registerUser/RegisterUser'))} /> */}
    <Redirect to="/login" />
  </Switch>
);

const DefaultContainer = () => (
  localStorage.getItem('access-token') !== null ?
    localStorage.getItem('role') === roleAdmin ?
      <Route component={AdminRoutes} />
      :
      <Route component={UserRouts} />
    :
    <Route component={LoggedOutList} />
)

const Routes = () => {
  return (
    <div className='d-flex flex-column min-vh-100'>
      <HashRouter>
        <Switch>
          <Route exact path="/" component={loadable(() => import('../pages/loginUserPage/LoginUserPage'))} />
          <Route path="/login" component={loadable(() => import('../pages/loginUserPage/LoginUserPage'))} />
          {/* <Route path="/register" component={loadable(() => import('../pages/registerUser/RegisterUser'))} /> */}
          <Route path="/finishRegistration/" component={loadable(() => import('../pages/finishRegistration/FinishRegistrationPage'))} />
          <Route component={DefaultContainer} />
        </Switch>
      </HashRouter>
    </div>
  )
};

export default Routes;

