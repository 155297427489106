import { GET_ROLE, GET_USER_PROFILE, LOGIN_FAILURE, LOGIN_SUCCESS, LOGOUT_ACTION, REGISTER_ACTION } from '../actions/index';

const INITIAL_STATE = {
  user: "",
  role: "",
  logInFailure: false,
  loadingLogin: false
}
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGOUT_ACTION:
      return {
        INITIAL_STATE
      }
    //  case LOGIN_ACTION:{
    //    console.log("LOGIN reducer")
    //  return{
    //    ...state,
    //    user:action.payload
    //  }
    // }
    case REGISTER_ACTION:
      return {
        ...state,
        user: action.payload
      }
    case GET_USER_PROFILE:
      return {
        ...state,
        user: action.payload,
      }
    case GET_ROLE:
      return {
        ...state,
        role: action.payload[0]
      }
    case LOGIN_FAILURE:
      return {
        ...state,
        logInFailure: true
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        logInFailure: false
      }
    default: return state
  }
}