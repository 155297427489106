import { GET_CASE_TYPES, ADD_CASE_TYPE, DELETE_CASE_TYPE, DELETE_CASE_TYPE_ACTION, EDIT_CASE_TYPE, UPDATE_CASE_TYPE } from '../actions/index';
import { GET_REGISTER_TYPES, ADD_REGISTER_TYPE, DELETE_REGISTER_TYPE, DELETE_REGISTER_TYPE_ACTION, EDIT_REGISTER_TYPE, UPDATE_REGISTER_TYPE } from '../actions/index'
import { GET_COURT_TYPES, ADD_COURT_TYPE, DELETE_COURT_TYPE, DELETE_COURT_TYPE_ACTION, EDIT_COURT_TYPE, UPDATE_COURT_TYPE } from '../actions/index';
import { GET_CASE_CATEGORIES, ADD_CASE_CATEGORY, DELETE_CASE_CATEGORY, DELETE_CASE_CATEGORY_ACTION, EDIT_CASE_CATEGORY, UPDATE_CASE_CATEGORY } from '../actions/index';
import { GET_ACTION_TYPES, ADD_ACTION_TYPE, DELETE_ACTION_TYPE, DELETE_ACTION_TYPE_ACTION, EDIT_ACTION_TYPE, UPDATE_ACTION_TYPE } from '../actions/index';
import { GET_PARTY_TYPES, ADD_PARTY_TYPE, DELETE_PARTY_TYPE, DELETE_PARTY_TYPE_ACTION, EDIT_PARTY_TYPE, UPDATE_PARTY_TYPE } from '../actions/index';
import { GET_COMPANIES,DELETE_COMPANY, DELETE_COMPANY_ACTION, EDIT_COMPANY, UPDATE_COMPANY } from '../actions/index';
const INITIAL_STATE = {
  caseTypes: [],
  registerTypes: [],
  courtTypes: [],
  caseCategories: [],
  actionTypes: [],
  partyTypes: [],
  companies: [],
  actionTypesPaging: {
    CurrentPage: 1,
    HasNext: true,
    HasPrevious: false,
    PageSize: 10,
    TotalCount: 1,
    TotalPages: 1,
    PageNumber: 1
  },
  caseTypesPaging: {
    CurrentPage: 1,
    HasNext: true,
    HasPrevious: false,
    PageSize: 10,
    TotalCount: 1,
    TotalPages: 1,
    PageNumber: 1
  },
  registerTypesPaging: {
    CurrentPage: 1,
    HasNext: true,
    HasPrevious: false,
    PageSize: 10,
    TotalCount: 1,
    TotalPages: 1,
    PageNumber: 1
  },
  courtTypesPaging: {
    CurrentPage: 1,
    HasNext: true,
    HasPrevious: false,
    PageSize: 10,
    TotalCount: 1,
    TotalPages: 1,
    PageNumber: 1
  },
  caseCategoriesPaging: {
    CurrentPage: 1,
    HasNext: true,
    HasPrevious: false,
    PageSize: 10,
    TotalCount: 1,
    TotalPages: 1,
    PageNumber: 1
  },
  partyTypesPaging: {
    CurrentPage: 1,
    HasNext: true,
    HasPrevious: false,
    PageSize: 10,
    TotalCount: 1,
    TotalPages: 1,
    PageNumber: 1
  },
  companiesPaging: {
    CurrentPage: 1,
    HasNext: true,
    HasPrevious: false,
    PageSize: 10,
    TotalCount: 1,
    TotalPages: 1,
    PageNumber: 1
  },
  loadingCaseTypes: true,
  loadingRegisterTypes: true,
  loadingCourtTypes: true,
  loadingCaseCategories: true,
  loadingActionTypes: true,
  loadingPartyTypes: true,
  loadingCompanies: true

}
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {

    case GET_CASE_TYPES: {

      return {
        ...state,
        caseTypes: action.payload.data,
        caseTypesPaging: action.payload.pagination,
        loadingCaseTypes: false
      }
    }
    case GET_REGISTER_TYPES: {
      return {
        ...state,
        registerTypes: action.payload.data,
        registerTypesPaging: action.payload.pagination,
        loadingRegisterTypes: false
      }
    }
    case GET_COURT_TYPES: {
      return {
        ...state,
        courtTypes: action.payload.data,
        courtTypesPaging: action.payload.pagination,
        loadingCourtTypes: false
      }
    }
    case GET_CASE_CATEGORIES: {
      return {
        ...state,
        caseCategories: action.payload.data,
        caseCategoriesPaging: action.payload.pagination,
        loadingCaseCategories: false
      }
    }
    case GET_ACTION_TYPES: {
      return {
        ...state,
        actionTypes: action.payload.data,
        actionTypesPaging: action.payload.pagination,
        loadingActionTypes: false
      }
    }
    case GET_PARTY_TYPES: {
      return {
        ...state,
        partyTypes: action.payload.data,
        partyTypesPaging: action.payload.pagination,
        loadingPartyTypes: false
      }
    }
    case GET_COMPANIES: {
      return {
        ...state,
        companies: action.payload.data,
        companiesPaging: action.payload.pagination,
        loadingCompanies: false
      }
    }
    case ADD_ACTION_TYPE:
      let newActionType = action.payload
      return {
        ...state,
        actionTypes: [...state.actionTypes, newActionType],
        actionTypesPaging:{
          ...state.actionTypesPaging,
          TotalCount: state.actionTypesPaging.TotalCount+1
        }

      }
    case ADD_PARTY_TYPE:
      let newPartyType = action.payload
      return {
        ...state,
        partyTypes: [...state.partyTypes, newPartyType],
        partyTypesPaging:{
          ...state.partyTypesPaging,
          TotalCount: state.partyTypesPaging.TotalCount+1,
        }
      }
    case ADD_CASE_TYPE: {
      let newCaseType = action.payload
      return {
        ...state,
        caseTypes: [...state.caseTypes, newCaseType],
        caseTypesPaging:{
          ...state.caseTypesPaging,
          TotalCount: state.caseTypesPaging.TotalCount+1
        }
      }
    }
    case ADD_CASE_CATEGORY: {
      let newCaseCategory = action.payload
      return {
        ...state,
        caseCategories: [...state.caseCategories, newCaseCategory],
        caseCategoriesPaging:{
          ...state.caseCategoriesPaging,
          TotalCount: state.caseCategoriesPaging.TotalCount+1
        }
      }
    }
    case ADD_COURT_TYPE: {
      let newCourtType = action.payload
      return {
        ...state,
        courtTypes: [...state.courtTypes, newCourtType],
        courtTypesPaging:{
          ...state.courtTypesPaging,
          TotalCount: state.courtTypesPaging.TotalCount+1
        }
      }
    }
    case ADD_REGISTER_TYPE: {
      let newRegisterType = action.payload
      return {
        ...state,
        registerTypes: [...state.registerTypes, newRegisterType],
        registerTypesPaging:{
          ...state.registerTypesPaging,
          TotalCount: state.registerTypesPaging.TotalCount+1
        }
      }
    }
    case DELETE_CASE_TYPE:
      return {
        ...state,
        caseTypes: [
          ...state.caseTypes.filter((deleteCaseType) => deleteCaseType.id !== action.payload)
        ],
        caseTypesPaging:{
          ...state.caseTypesPaging,
          TotalCount: state.caseTypesPaging.TotalCount-1
        }

      }
    case DELETE_REGISTER_TYPE:
      return {
        ...state,
        registerTypes: [
          ...state.registerTypes.filter((deleteRegisterType) => deleteRegisterType.id !== action.payload)
        ],
        registerTypesPaging:{
          ...state.registerTypesPaging,
          TotalCount: state.registerTypesPaging.TotalCount-1
        }

      }
    case DELETE_COURT_TYPE:
      return {
        ...state,
        courtTypes: [
          ...state.courtTypes.filter((deleteCourtType) => deleteCourtType.id !== action.payload)
        ],
        courtTypesPaging:{
          ...state.courtTypesPaging,
          TotalCount: state.courtTypesPaging.TotalCount-1
        }

      }
    case DELETE_CASE_CATEGORY:
      return {
        ...state,
        caseCategories: [
          ...state.caseCategories.filter((deleteCaseCategory) => deleteCaseCategory.id !== action.payload)
        ],
        caseCategoriesPaging:{
          ...state.caseCategoriesPaging,
          TotalCount: state.caseCategoriesPaging.TotalCount-1
        }

      }
    case DELETE_ACTION_TYPE:
      return {
        ...state,
        actionTypes: [
          ...state.actionTypes.filter((deleteActionType) => deleteActionType.id !== action.payload)
        ],
        actionTypesPaging:{
          ...state.actionTypesPaging,
          TotalCount: state.actionTypesPaging.TotalCount-1
        }

      }
    case DELETE_PARTY_TYPE:
      return {
        ...state,
        partyTypes: [
          ...state.partyTypes.filter((deletePartyType) => deletePartyType.id !== action.payload)
        ],
        partyTypesPaging:{
          ...state.partyTypesPaging,
          TotalCount: state.partyTypesPaging.TotalCount-1,
        }
      }
      case DELETE_COMPANY:
      return {
        ...state,
        companies: [
          ...state.companies.filter((deleteCompany) => deleteCompany.id !== action.payload)
        ],
        companiesPaging:{
          ...state.companiesPaging,
          TotalCount: state.companiesPaging.TotalCount-1,
        }
      }
    case DELETE_CASE_TYPE_ACTION:
      return {
        ...state,
        caseTypes: [
          ...state.caseTypes.map((caseType) => caseType.id === action.payload ? { ...caseType, deleting: !caseType.deleting } : { ...caseType, deleting: false })
        ],
      }
    case DELETE_REGISTER_TYPE_ACTION:
      return {
        ...state,
        registerTypes: [
          ...state.registerTypes.map((registerType) => registerType.id === action.payload ? { ...registerType, deleting: !registerType.deleting } : { ...registerType, deleting: false })
        ],
      }
    case DELETE_COURT_TYPE_ACTION:
      return {
        ...state,
        courtTypes: [
          ...state.courtTypes.map((courtType) => courtType.id === action.payload ? { ...courtType, deleting: !courtType.deleting } : { ...courtType, deleting: false })
        ],
      }
    case DELETE_ACTION_TYPE_ACTION:
      return {
        ...state,
        actionTypes: [
          ...state.actionTypes.map((actionType) => actionType.id === action.payload ? { ...actionType, deleting: !actionType.deleting } : { ...actionType, deleting: false })
        ],
      }
    case DELETE_PARTY_TYPE_ACTION:
      return {
        ...state,
        partyTypes: [
          ...state.partyTypes.map((partyType) => partyType.id === action.payload ? { ...partyType, deleting: !partyType.deleting } : { ...partyType, deleting: false })
        ],
      }
    case DELETE_CASE_CATEGORY_ACTION:
      return {
        ...state,
        caseCategories: [
          ...state.caseCategories.map((caseCategory) => caseCategory.id === action.payload ? { ...caseCategory, deleting: !caseCategory.deleting } : { ...caseCategory, deleting: false })
        ],
      }
      case DELETE_COMPANY_ACTION:
        return {
          ...state,
          companies: [
            ...state.companies.map((company) => company.id === action.payload ? { ...company, deleting: !company.deleting } : { ...company, deleting: false })
          ],
        }
    case EDIT_CASE_TYPE:
      return {
        ...state,
        caseTypes: [
          ...state.caseTypes.map((caseTypeEdit) => caseTypeEdit.id === action.payload ? { ...caseTypeEdit, editing: !caseTypeEdit.editing } : { ...caseTypeEdit, editing: false })
        ],
      }
    case EDIT_REGISTER_TYPE:
      return {
        ...state,
        registerTypes: [
          ...state.registerTypes.map((regTypeEdit) => regTypeEdit.id === action.payload ? { ...regTypeEdit, editing: !regTypeEdit.editing } : { ...regTypeEdit, editing: false })
        ],
      }
    case EDIT_COURT_TYPE:
      return {
        ...state,
        courtTypes: [
          ...state.courtTypes.map((courtTypeEdit) => courtTypeEdit.id === action.payload ? { ...courtTypeEdit, editing: !courtTypeEdit.editing } : { ...courtTypeEdit, editing: false })
        ],
      }
    case EDIT_CASE_CATEGORY:
      return {
        ...state,
        caseCategories: [
          ...state.caseCategories.map((caseCategoryEdit) => caseCategoryEdit.id === action.payload ? { ...caseCategoryEdit, editing: !caseCategoryEdit.editing } : { ...caseCategoryEdit, editing: false })
        ],
      }
    case EDIT_ACTION_TYPE:
      return {
        ...state,
        actionTypes: [
          ...state.actionTypes.map((actionTypeEdit) => actionTypeEdit.id === action.payload ? { ...actionTypeEdit, editing: !actionTypeEdit.editing } : { ...actionTypeEdit, editing: false })
        ],
      }
    case EDIT_PARTY_TYPE:
      return {
        ...state,
        partyTypes: [
          ...state.partyTypes.map((partyTypeEdit) => partyTypeEdit.id === action.payload ? { ...partyTypeEdit, editing: !partyTypeEdit.editing } : { ...partyTypeEdit, editing: false })
        ],
      }
      case EDIT_COMPANY:
        return {
          ...state,
          companies: [
            ...state.companies.map((company) => company.id === action.payload ? { ...company, editing: !company.editing } : { ...company, editing: false })
          ],
        }
    case UPDATE_CASE_TYPE:
      return {
        ...state,
        caseTypes: [...state.caseTypes.map((caseTypeEdit) => {
          if (caseTypeEdit.id === action.payload.id) {
            return {
              ...caseTypeEdit,
              name: action.payload.name,
              canDelete: action.payload.canDelete,
            }
          } else return caseTypeEdit;
        })]
      }
    case UPDATE_REGISTER_TYPE:
      return {
        ...state,
        registerTypes: [...state.registerTypes.map((registerTypeEdit) => {
          if (registerTypeEdit.id === action.payload.id) {
            return {
              ...registerTypeEdit,
              name: action.payload.name,
              caseTypeId: action.payload.caseTypeId,
              courtId: action.payload.courtId,
              court: action.payload.court
            }
          } else return registerTypeEdit;
        })]
      }
    case UPDATE_COURT_TYPE:
      return {
        ...state,
        courtTypes: [...state.courtTypes.map((courtTypeEdit) => {
          if (courtTypeEdit.id === action.payload.id) {
            return {
              ...courtTypeEdit,
              name: action.payload.name,
              canDelete: action.payload.canDelete,
              caseTypeId: action.payload.caseTypeId
            }
          } else return courtTypeEdit;
        })]
      }
    case UPDATE_CASE_CATEGORY:
      return {
        ...state,
        caseCategories: [...state.caseCategories.map((caseCategoryEdit) => {
          if (caseCategoryEdit.id === action.payload.id) {
            return {
              ...caseCategoryEdit,
              title: action.payload.title,
              firstPartyTitle: action.payload.firstPartyTitle,
              secondPartyTitle: action.payload.secondPartyTitle,
              useThirdParty:action.payload.useThirdParty
            }
          } else return caseCategoryEdit;
        })]
      }
    case UPDATE_ACTION_TYPE:
      return {
        ...state,
        actionTypes: [...state.actionTypes.map((actionTypeEdit) => {
          if (actionTypeEdit.id === action.payload.id) {
            return {
              ...actionTypeEdit,
              name: action.payload.name,
            }
          } else return actionTypeEdit;
        })]
      }
    case UPDATE_PARTY_TYPE:
      return {
        ...state,
        partyTypes: [...state.partyTypes.map((partyTypeEdit) => {
          if (partyTypeEdit.id === action.payload.id) {
            return {
              ...partyTypeEdit,
              title: action.payload.title,
            }
          } else return partyTypeEdit;
        })]
      }
      case UPDATE_COMPANY:
      return {
        ...state,
        companies: [...state.companies.map((companyEdit) => {
          if (companyEdit.id === action.payload.id) {
            return {
              ...companyEdit,
              name: action.payload.name,
            }
          } else return companyEdit;
        })]
      }
    default: return state;
  }
}