import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink } from "react-router-dom";
import { compose } from 'redux';
import { roleAdmin } from '../../constants/Constants';
import '../../override.css';
import Logout from '../logout/Logout';
import './Menu.scss';

function Menu() {
  const [show, setShow] = useState(false)
  const { t } = useTranslation()
  const showModal = useCallback(() => {

    setShow(true);
  }, [setShow])

  const hideModal = useCallback(() => {
    setShow(false);
  }, [setShow])

  let firstName = localStorage.getItem("firstName")
  let userName = localStorage.getItem("userName")
  let role = localStorage.getItem("role")
  const activeStyle = { backgroundColor: "#d1ab66" }
  return (
    <>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <img src="logos/lawOfficeTransparent_logo.png" alt="Law office Stankovic" className=" img-responsive brand-image " />
        <div className="sidebar">
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu">
              <li className="nav-item" onClick={showModal}>
                <div className="nav-link nav-link-menu menu_a_name">
                  <i className="nav-icon fas fa-sign-out-alt" />
                  {firstName !== "null" ?
                    <p> {firstName}</p> :
                    <p>{userName}</p>}
                </div>
              </li>
              <li className="nav-item">
                <NavLink to='/contacts' className='nav-link nav-link-menu' activeStyle={activeStyle}>
                  <i className="nav-icon fas fa-user " />
                  <p>{t('Menu.Contacts')}</p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to='/cases' className='nav-link nav-link-menu' activeStyle={activeStyle}>
                  <i className="nav-icon fas fa-file-alt"></i>
                  <p>
                    {t('Menu.Cases')}
                  </p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to='/hearings' className='nav-link nav-link-menu' activeStyle={activeStyle}>
                  <i className="nav-icon fas fa-gavel" />
                  <p>
                    {t('Menu.Hearings')}
                  </p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to='/actions' className='nav-link nav-link-menu' activeStyle={activeStyle}>
                  <i className="nav-icon fa fa-paperclip" aria-hidden="true"></i>
                  <p>
                    {t('Menu.Actions')}
                  </p>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink to='/calendar' className='nav-link nav-link-menu' activeStyle={activeStyle}>
                  <i className=" nav-icon fas fa-calendar-alt"></i>
                  <p>
                    {t('Menu.Calendar')}
                  </p>
                </NavLink>
              </li>
              {role === roleAdmin &&
                <li className="nav-item">
                  <NavLink to='/users' className='nav-link nav-link-menu' activeStyle={activeStyle}>
                    <i className=" nav-icon fas fa-users"></i>
                    <p>
                      {t('Menu.UserMenagment')}
                    </p>
                  </NavLink>
                </li>
              }
              {role === roleAdmin &&
                <li className="nav-item">
                  <NavLink to='/settings' className='nav-link nav-link-menu' activeStyle={activeStyle}>
                    <i className="nav-icon fas fa-cog" />
                    <p>
                      {t('Menu.Settings')}
                    </p>
                  </NavLink>
                </li>
              }
              {/* <li className="nav-item li-aboutUs-position">
                <NavLink to='/about' className="nav-link nav-link-menu" activeStyle={activeStyle}>
                  <i className="nav-icon fas fa-address-card"></i>
                  <p>
                    {t('Menu.AboutUs')}
                  </p>
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink to='/contact' className="nav-link nav-link-menu" activeStyle={activeStyle}>
                  <i className="nav-icon fas fa-file-signature"></i>
                  <p>
                    {t('Menu.ContactUs')}
                  </p>
                </NavLink>
              </li> */}
            </ul>
          </nav>
        </div>
      </aside>
      <aside className="control-sidebar control-sidebar-dark">
      </aside>
      <Logout show={show} handleClose={hideModal}></Logout>
    </>
  )
}

function mapStateToProps(state) {
  return {
    user: state.auth.user,
    role: state.auth.role
  }
}

export default compose(
  connect(mapStateToProps, null)
)(Menu)