import { GET_HEARINGS, GET_CASES_HEARINGS, ADD_HEARING, DELETE_HEARING, DELETE_HEARING_ACTION, EDIT_HEARING, UPDATE_HEARING, FILTER_HEARINGS } from '../actions/index';


const INITIAL_STATE = {
  hearings: [],
  hearing: {
    judgeId: null,
    dateCreated: null,
    courtroomNumber: null,
    note: null,
    judge: null,
    case: null,
    users: []
  },
  filterHearings: [],
  loadingHearings: true,
  hearingsPaging: {
    CurrentPage: 1,
    HasNext: true,
    HasPrevious: false,
    PageSize: 10,
    TotalCount: 1,
    TotalPages: 1,
    PageNumber: 1
  },
  filterHearingsPaging: {
    CurrentPage: 1,
    HasNext: true,
    HasPrevious: false,
    PageSize: 10,
    TotalCount: 1,
    TotalPages: 1,
    PageNumber: 1
  },
  casesHearings: [],
  loadingCasesHearings: true,
  casesHearingsPaging: {
    CurrentPage: 1,
    HasNext: true,
    HasPrevious: false,
    PageSize: 10,
    TotalCount: 1,
    TotalPages: 1,
    PageNumber: 1
  },
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {

    case GET_HEARINGS:
      return {
        ...state,
        hearings: action.payload.data,
        hearingsPaging: action.payload.pagination,
        loadingHearings: false
      }
    case GET_CASES_HEARINGS:
      return {
        ...state,
        casesHearings: action.payload.data,
        casesHearingsPaging: action.payload.pagination,
        loadingCasesHearings: false
      }
    case ADD_HEARING:
      let newHearing = action.payload
      return {
        ...state,
        hearings: [...state.hearings, newHearing],
        filterHearings: [...state.filterHearings, newHearing],
        hearingsPaging: {
          ...state.hearingsPaging,
          TotalCount: state.hearingsPaging.TotalCount + 1,
        },
        filterHearingsPaging: {
          ...state.filterHearingsPaging,
          TotalCount: state.filterHearingsPaging.TotalCount + 1,
        }
      }
    case DELETE_HEARING:
      return {
        ...state,
        hearings: [
          ...state.hearings.filter((hearing) => hearing.id !== action.payload)
        ],
        hearingsPaging: {
          ...state.hearingsPaging,
          TotalCount: state.hearingsPaging.TotalCount - 1,
        },
        filterHearingsPaging: {
          ...state.filterHearingsPaging,
          TotalCount: state.filterHearingsPaging.TotalCount - 1,
        }
      }
    case DELETE_HEARING_ACTION:
      return {
        ...state,
        hearings: [
          ...state.hearings.map((hearing) => hearing.id === action.payload ? { ...hearing, deleting: !hearing.deleting } : { ...hearing, deleting: false })
        ],
      }
    case EDIT_HEARING:
      return {
        ...state,
        hearings: [
          ...state.hearings.map((hearing) => hearing.id === action.payload ? { ...hearing, editing: !hearing.editing } : { ...hearing, editing: false })
        ],
      }
    case UPDATE_HEARING:
      return {
        ...state,
        hearings: [...state.hearings.map((hearing) => {
          if (hearing.id === action.payload.id) {
            return {
              ...hearing,
              judgeId: action.payload.judgeId,
              dateCreated: action.payload.dateCreated,
              courtroomNumber: action.payload.courtroomNumber,
              note: action.payload.note,
              judge: action.payload.judge,
              case: action.payload.case,
              userHearings: action.payload.userHearings
            }
          } else return hearing;
        })],
        filterHearings: [...state.filterHearings.map((hearing) => {
          if (hearing.id === action.payload.id) {
            return {
              ...hearing,
              judgeId: action.payload.judgeId,
              dateCreated: action.payload.dateCreated,
              courtroomNumber: action.payload.courtroomNumber,
              note: action.payload.note,
              judge: action.payload.judge,
              case: action.payload.case

            }
          } else return hearing;
        })]
      }
    case FILTER_HEARINGS:
      console.log(action.payload.data)
      return {
        ...state,
        filterHearings: action.payload.data,
        filterHearingsPaging: action.payload.pagination,
      }
    default: return state
  };
}
